import React from 'react';
import logo from '../images/logo.jpg';
import { useNavigate, NavLink } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <nav className="absolute top-0 left-0 w-full z-50 p-4 bg-transparent">
      {/* Conteneur pour la version grand écran */}
      <div className="hidden lg:flex items-center justify-between w-full">
        <div className="flex items-center">
          <img
            src={logo}
            alt="Sarah et la pâtisserie"
            className="h-[10rem] rounded-full object-cover cursor-pointer"
            onClick={() => {
              navigate('/Home');
            }}
          />
        </div>
        <ul className="flex space-x-[5rem] mr-[4rem]">
          <li>
            <NavLink
              to="/"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Accueil
            </NavLink>
          </li>{' '}
          <li>
            <NavLink
              to="/#about"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Qui suis-je ?
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Creations"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Mes réalisations
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/#contact"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Conteneur pour la version tablette */}
      <div className="flex flex-col items-center lg:hidden">
        <div className="mb-4">
          <img
            src={logo}
            alt="Sarah et la pâtisserie"
            className="h-[8rem] w-[8rem] rounded-full object-cover cursor-pointer"
            onClick={() => {
              navigate('/Home');
            }}
          />
        </div>
        <ul className="flex flex-col items-center space-y-4">
          <li>
            <NavLink
              to="/"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Accueil
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/#about"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Qui suis-je ?
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Creations"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Mes réalisations
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/#contact"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
