import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import CardCreation from './CardCreation';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

const LastestCreations = () => {
  const [realisations, setRealisations] = useState([]);
  const [loading, setLoading] = useState(true);
  const swiperRef = useRef(null); // Ref pour Swiper

  // Utiliser useEffect pour récupérer les données de Strapi
  useEffect(() => {
    const fetchRealisations = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_STRAPI_BASE_URL +
            '/api/realisations?populate=*&sort=publishedAt:desc'
        );
        const latestRealisations = response.data.data.slice(0, 3);
        setRealisations(latestRealisations);
        setLoading(false);
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des réalisations :',
          error
        );
        setLoading(false);
      }
    };

    fetchRealisations();

    // Démarrer l'autoplay après le montage du composant
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, []);

  if (loading) {
    return <p>Chargement des réalisations...</p>;
  }

  return (
    <div className="bg-marronclair pb-10">
      <div className="flex items-center justify-center">
        <img
          src="/images/gateau1.png"
          alt="Gâteau"
          className="h-[6rem] sm:h-[8rem] lg:h-[10rem] mb-0"
        />
        <h2 className="text-center text-marronfonce text-xl sm:text-2xl font-bold">
          Mes dernières réalisations
        </h2>
        <img
          src="/images/gateau1.png"
          alt="Gâteau"
          className="h-[6rem] sm:h-[8rem] lg:h-[10rem] mb-0 transform scale-x-[-1]"
        />
      </div>

      <hr className="border-t-2 border-marronfonce w-3/4 md:w-1/2 mx-auto mb-10" />

      {/* Carrousel pour mobile et tablette */}
      <div className="flex justify-center w-full max-w-screen-lg mx-auto">
        <Swiper
          ref={swiperRef}
          spaceBetween={16}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            clickable: true,
          }}
          speed={1000}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
          modules={[Autoplay, Pagination]}
        >
          {realisations.map((realisation) => {
            const imageData = realisation.attributes.image.data[0].attributes;
            const imageUrl =
              imageData.formats.large?.url ||
              imageData.formats.medium?.url ||
              imageData.formats.small?.url ||
              imageData.url;
            const fullImageUrl = `${process.env.REACT_APP_STRAPI_BASE_URL}${imageUrl}`;

            return (
              <SwiperSlide
                key={realisation.id}
                className="flex justify-center items-center py-10"
              >
                <CardCreation
                  key={realisation.id}
                  index={realisation.id}
                  idGateau={realisation.id}
                  nomGateau={realisation.attributes.nom}
                  imageGateau={fullImageUrl}
                  className="w-full max-w-sm mx-auto"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="flex justify-center mt-8">
        <NavLink
          to="/Creations"
          className="inline-block px-6 py-3 text-lg text-marronclair font-semibold bg-marronfonce hover:bg-opacity-80 hover:shadow-lg transition duration-300 ease-in-out"
        >
          Voir mes réalisations
        </NavLink>
      </div>
    </div>
  );
};

export default LastestCreations;
