// HomeBlock.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import backgroundImage from '../images/image-accueil2.jpg';

const HomeBlock = () => {
  return (
    <div
      className="relative h-screen flex items-center justify-center bg-cover bg-top"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="relative text-center mt-20 px-4 md:px-8">
        <h1 className="text-[4rem] sm:text-[6rem] lg:text-[9rem] text-rose font-corinthia mt-20 sm:mt-0 sm:mb-[rem]">
          Sarah et la pâtisserie
        </h1>
        <h2 className="text-lg hidden sm:block mdlg:text-2xl text-marronclair max-w-3xl mx-auto mb-[6rem]">
          "Gourmandise: Source inépuisable de bonheur."
          <p className="text-end text-sm">Pierre Hermé.</p>
        </h2>
        <NavLink
          to="/Creations" // Changez le lien en fonction de votre structure
          className="inline-block px-6 py-3 text-lg font-semibold bg-marronclair hover:bg-rose shadow-lg transition duration-300 ease-in-out"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Voir mes réalisations
        </NavLink>
      </div>
    </div>
  );
};

export default HomeBlock;
