import React, { useState, useEffect } from 'react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { send } from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const FooterTwo = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let formErrors = {};

    if (
      !formData.name.trim() ||
      /[^a-zA-Z\s]/.test(formData.name) ||
      formData.name.length < 2
    ) {
      formErrors.name =
        'Veuillez entrer un nom valide sans chiffres et au moins 2 caractères.';
    }

    if (!formData.email.trim() || !validateEmail(formData.email)) {
      formErrors.email = 'Veuillez entrer une adresse email valide.';
    }

    if (!formData.message.trim() || formData.message.length < 2) {
      formErrors.message =
        "Veuillez entrer un message d'au moins 2 caractères.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    console.log('Captcha value:', value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert('Veuillez compléter le reCAPTCHA.');
      return;
    }

    if (validateForm()) {
      setIsSending(true);
      try {
        await send(
          'service_j5cqvre',
          'template_u3rksnr',
          formData,
          '3qpx-EutG4C8xVuva'
        );
        setSuccessMessage('Votre message a été envoyé avec succès !');
        setFormData({ name: '', email: '', message: '' });
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } catch (error) {
        console.error("Erreur d'envoi:", error);
        setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
      } finally {
        setIsSending(false);
      }
    }
  };

  return (
    <footer
      id="contactmarron"
      className="bg-marronfonce text-marronclair pt-10 pb-1 px-4"
    >
      <div className="container mx-auto grid grid-cols-1 lgz:grid-cols-2 gap-8">
        {/* Formulaire de contact */}
        <div className="flex flex-col border-solid border px-10 py-5 w-full sm:w-3/4 mx-auto shadow-xl bg-marronclair">
          <h3 className="text-xl font-bold mb-4 text-center text-marronfonce">
            Contactez-moi
          </h3>
          <form className="space-y-3 sm:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-marronfonce mb-2">
                Nom :
              </label>
              <input
                id="name"
                name="name"
                type="text"
                placeholder="Votre nom"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 sm:p-3 bg-marronfonce text-marronclair placeholder-marronclair"
                required
              />
              {errors.name && (
                <p className="text-red-500 text-xs mt-1">{errors.name}</p>
              )}
            </div>
            <div>
              <label htmlFor="email" className="block text-marronfonce mb-2">
                Email :
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Votre email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 sm:p-3 bg-marronfonce text-marronclair placeholder-marronclair"
                required
              />
              {errors.email && (
                <p className="text-red-500 text-xs mt-1">{errors.email}</p>
              )}
            </div>
            <div>
              <label htmlFor="message" className="block text-marronfonce mb-2">
                Message :
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Votre message"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-2 sm:p-3 bg-marronfonce text-marronclair placeholder-marronclair"
                rows="5"
                required
              />
              {errors.message && (
                <p className="text-red-500 text-xs mt-1">{errors.message}</p>
              )}
            </div>
            <ReCAPTCHA
              sitekey="6LeNEfQpAAAAAOmYc5QyltW2JWCNXWhs-3CcLdG6"
              onChange={handleCaptchaChange}
            />
            <div className="text-center">
              <button
                type="submit"
                className="bg-marronfonce text-marronclair font-semibold py-1 px-4 sm:py-3 sm:px-6 shadow-lg hover:bg-opacity-80 transition-colors duration-300"
                disabled={isSending}
              >
                {isSending ? 'Envoi en cours...' : 'Envoyer'}
              </button>
            </div>
            {successMessage && (
              <p className="text-green-500 text-center">{successMessage}</p>
            )}
            {errorMessage && (
              <p className="text-red-500 text-center">{errorMessage}</p>
            )}
          </form>
        </div>

        {/* Informations et liens sociaux */}
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-2xl md:text-3xl font-bold mb-12 text-center md:text-right leading-tight">
            Merci pour votre visite
          </h3>
          <p className="text-center md:text-right mb-6">
            Retrouvez-moi également sur les réseaux sociaux !
          </p>
          <div className="flex flex-col justify-center mb-10 md:mb-28 space-y-4 md:space-y-4">
            <a
              href="https://www.facebook.com/sarah.et.la.patisserie"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-marronclair transition-colors duration-300 hover:opacity-80"
            >
              <FaFacebook size={40} className="mr-2" /> Sarah et la pâtisserie
            </a>
            <a
              href="https://www.instagram.com/sarah.et.la.patisserie/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-marronclair transition-colors duration-300 hover:opacity-80"
            >
              <FaInstagram size={40} className="mr-2" /> @sarah.et.la.patisserie
            </a>
          </div>

          {/* Liens de navigation */}
          <div className="text-xs sm:text-base flex flex-row space-x-3 space-y-0 md:space-y-0">
            <NavLink
              to="/"
              className="hover:opacity-80 duration-150"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Accueil
            </NavLink>
            <NavLink
              to="/Creations"
              className="hover:opacity-80 duration-150"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Les réalisations
            </NavLink>
            <NavLink to="/#about" className="hover:opacity-80 duration-150">
              Qui suis-je ?
            </NavLink>
            <NavLink
              to="/LegalMentions"
              className="hover:opacity-80 duration-150"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Mentions légales
            </NavLink>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className="mt-10 border-t border-gray-600 pt-4">
        <p className="text-sm text-marronclair text-left">
          2024 © Sarah et la pâtisserie. Tous droits réservés. Created by{' '}
          <NavLink
            className="font-semibold hover:opacity-80 duration-150"
            to="https://frevillefranck.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fréville Franck
          </NavLink>
        </p>
      </div>
    </footer>
  );
};

export default FooterTwo;
