import React, { useState, useEffect } from 'react';
import NavBarTwo from '../components/NavBarTwo';
import axios from 'axios';
import FooterTwo from '../components/FooterTwo';

const Backoffice = () => {
  const [activeTab, setActiveTab] = useState('add');
  const [realisation, setRealisation] = useState({
    nom: '',
    description: '',
    image: null,
    ingredients: [{ item: '' }],
    recette: [{ etape: 1, description: '' }],
  });
  const [realisationId, setRealisationId] = useState(null);
  const [realisationsList, setRealisationsList] = useState([]);

  useEffect(() => {
    const fetchRealisationsList = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_STRAPI_BASE_URL + '/api/realisations'
        );
        setRealisationsList(response.data.data);
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des réalisations :',
          error
        );
      }
    };
    fetchRealisationsList();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setRealisationId(null);
    setRealisation({
      nom: '',
      description: '',
      image: null,
      ingredients: [{ item: '' }],
      recette: [{ etape: 1, description: '' }],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRealisation({
      ...realisation,
      [name]: value,
    });
  };

  const handleRecetteChange = (index, e) => {
    const { name, value } = e.target;
    const newRecette = realisation.recette.map((step, i) =>
      i === index ? { ...step, [name]: value } : step
    );
    setRealisation({
      ...realisation,
      recette: newRecette,
    });
  };

  const handleIngredientChange = (index, e) => {
    const { value } = e.target;
    const newIngredients = realisation.ingredients.map((ingredient, i) =>
      i === index ? { ...ingredient, item: value } : ingredient
    );
    setRealisation({
      ...realisation,
      ingredients: newIngredients,
    });
  };

  const handleImageChange = (e) => {
    setRealisation({
      ...realisation,
      image: e.target.files[0],
    });
  };

  const addStep = () => {
    setRealisation({
      ...realisation,
      recette: [
        ...realisation.recette,
        { etape: realisation.recette.length + 1, description: '' },
      ],
    });
  };

  const addIngredient = () => {
    setRealisation({
      ...realisation,
      ingredients: [...realisation.ingredients, { item: '' }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        nom: realisation.nom,
        description: realisation.description,
        recette: realisation.recette,
        ingredients: realisation.ingredients.map((ing) => ing.item),
      })
    );

    if (realisation.image) {
      formData.append('files.image', realisation.image);
    }

    try {
      const url =
        activeTab === 'edit'
          ? process.env.REACT_APP_STRAPI_BASE_URL +
            `/api/realisations/${realisationId}`
          : process.env.REACT_APP_STRAPI_BASE_URL + '/api/realisations';

      const method = activeTab === 'edit' ? 'put' : 'post';

      await axios({
        method: method,
        url: url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      window.location.reload();
    } catch (error) {
      console.error(
        "Erreur lors de l'ajout/modification de la réalisation:",
        error
      );
    }
  };

  const handleSelectRealisation = async (e) => {
    const selectedId = e.target.value;
    setRealisationId(selectedId);
    try {
      const response = await axios.get(
        process.env.REACT_APP_STRAPI_BASE_URL +
          `/api/realisations/${selectedId}?populate=*`
      );
      const data = response.data.data.attributes;
      setRealisation({
        nom: data.nom,
        description: data.description,
        image: null,
        ingredients: data.ingredients
          ? data.ingredients.map((item) => ({ item }))
          : [{ item: '' }],
        recette: data.recette
          ? data.recette.map((step, index) => ({
              etape: index + 1,
              description: step.description,
            }))
          : [{ etape: 1, description: '' }],
      });
    } catch (error) {
      console.error('Erreur lors de la sélection de la réalisation:', error);
    }
  };

  const handleDeleteRealisation = async () => {
    try {
      await axios.delete(
        process.env.REACT_APP_STRAPI_BASE_URL +
          `/api/realisations/${realisationId}`
      );
      window.location.reload();
    } catch (error) {
      console.error('Erreur lors de la suppression de la réalisation:', error);
    }
  };

  return (
    <div className="bg-marronclair min-h-screen">
      <NavBarTwo />
      <div className="container mx-auto p-6">
        <div className="flex justify-center mb-6">
          <button
            onClick={() => handleTabChange('add')}
            className={`px-4 py-2 mx-2 font-semibold rounded-lg ${
              activeTab === 'add'
                ? 'bg-marronfonce text-white'
                : 'bg-white text-marronfonce'
            }`}
          >
            Ajouter
          </button>
          <button
            onClick={() => handleTabChange('edit')}
            className={`px-4 py-2 mx-2 font-semibold rounded-lg ${
              activeTab === 'edit'
                ? 'bg-marronfonce text-white'
                : 'bg-white text-marronfonce'
            }`}
          >
            Modifier
          </button>
          <button
            onClick={() => handleTabChange('delete')}
            className={`px-4 py-2 mx-2 font-semibold rounded-lg ${
              activeTab === 'delete'
                ? 'bg-marronfonce text-white'
                : 'bg-white text-marronfonce'
            }`}
          >
            Supprimer
          </button>
        </div>

        {activeTab === 'add' || activeTab === 'edit' ? (
          <form onSubmit={handleSubmit} className="space-y-6">
            {activeTab === 'edit' && (
              <div>
                <label className="block text-lg text-marronfonce mb-2">
                  Sélectionnez une réalisation à modifier :
                </label>
                <select
                  value={realisationId || ''}
                  onChange={handleSelectRealisation}
                  className="w-full p-2 border rounded"
                >
                  <option value="" disabled>
                    -- Sélectionnez une réalisation --
                  </option>
                  {realisationsList.map((realisation) => (
                    <option key={realisation.id} value={realisation.id}>
                      {realisation.attributes.nom}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div>
              <label
                className="block text-lg text-marronfonce mb-2"
                htmlFor="nom"
              >
                Nom :
              </label>
              <input
                id="nom"
                name="nom"
                type="text"
                value={realisation.nom}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label
                className="block text-lg text-marronfonce mb-2"
                htmlFor="description"
              >
                Description :
              </label>
              <textarea
                id="description"
                name="description"
                value={realisation.description}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                rows="3"
              />
            </div>
            <div>
              <label
                className="block text-lg text-marronfonce mb-2"
                htmlFor="image"
              >
                Image :
              </label>
              <input
                id="image"
                name="image"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="hidden">
              <label className="block text-lg text-marronfonce mb-2">
                Ingrédients :
              </label>
              {realisation.ingredients.map((ingredient, index) => (
                <div key={index} className="mb-2">
                  <input
                    name="ingredient"
                    type="text"
                    value={ingredient.item}
                    onChange={(e) => handleIngredientChange(index, e)}
                    className="w-full p-2 border rounded"
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={addIngredient}
                className="mt-2 p-2 bg-marronfonce text-white rounded"
              >
                Ajouter un Ingrédient
              </button>
            </div>
            <div className="hidden">
              <label className="block text-lg text-marronfonce mb-2">
                Recette :
              </label>
              {realisation.recette.map((step, index) => (
                <div key={index} className="mb-2">
                  <label className="block mb-1 text-marronfonce">
                    Étape {step.etape} :
                  </label>
                  <input
                    name="description"
                    type="text"
                    value={step.description}
                    onChange={(e) => handleRecetteChange(index, e)}
                    className="w-full p-2 border rounded"
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={addStep}
                className="mt-2 p-2 bg-marronfonce text-white rounded"
              >
                Ajouter une Étape
              </button>
            </div>
            <button
              type="submit"
              className="p-2 bg-marronfonce text-white rounded"
            >
              {activeTab === 'add'
                ? 'Ajouter la Réalisation'
                : 'Modifier la Réalisation'}
            </button>
          </form>
        ) : (
          activeTab === 'delete' && (
            <div className="space-y-4">
              <label className="block text-lg text-marronfonce mb-2">
                Sélectionnez une réalisation à supprimer :
              </label>
              <select
                value={realisationId || ''}
                onChange={(e) => setRealisationId(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="" disabled>
                  -- Sélectionnez une réalisation --
                </option>
                {realisationsList.map((realisation) => (
                  <option key={realisation.id} value={realisation.id}>
                    {realisation.attributes.nom}
                  </option>
                ))}
              </select>
              <button
                onClick={handleDeleteRealisation}
                className="p-2 bg-red-500 text-white rounded mt-4"
              >
                Supprimer la Réalisation
              </button>
            </div>
          )
        )}
      </div>
      <FooterTwo />
    </div>
  );
};

export default Backoffice;
