import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const About = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <div id="about" className="bg-marronfonce w-full">
      <div className="w-full py-10 ">
        <div className="flex items-center justify-center mb-6">
          <img
            src="/images/gateau2.png"
            alt="Gâteau"
            className="h-[2rem] md:h-[3rem] mb-0 mr-6 md:mr-16 transform scale-x-[-1]"
          />
          <h2 className="text-center text-rose text-xl sm:text-2xl font-bold">
            Qui suis-je ?
          </h2>
          <img
            src="/images/gateau2.png"
            alt="Gâteau"
            className="h-[2rem] md:h-[3rem] mb-0 ml-6 md:ml-16"
          />
        </div>

        <hr className="border-t-2 border-rose w-1/2 mx-auto mb-10" />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 px-10 lg:px-0">
          {/* Première ligne */}
          <div className="relative flex justify-center items-center">
            <img
              src="/images/sarah_noiretblanc.jpg"
              alt="Pâtissière"
              className="w-full h-[calc(50vw)] lg:h-[calc(25vw)] object-cover"
            />
          </div>
          <div className="flex justify-center items-center p-4 sm:p-10 sm:text-center md:text-start 2xl:p-[10rem]">
            <p className="text-base sm:text-xl font-thin text-rose sm:leading-loose">
              Moi, c’est Sarah ! J’ai actuellement 18 ans et suis passionnée par
              la pâtisserie depuis petite. Je débute un CAP chocolat en
              alternance afin de me spécialiser après avoir obtenu mon bac pro
              boulangerie-pâtisserie ! Je partage depuis pas mal de temps mes
              réalisations sur les réseaux sociaux et maintenant sur ce site !
            </p>
          </div>

          {/* Deuxième ligne */}
          <div className="flex justify-center items-center p-4 sm:p-10 sm:text-center md:text-start 2xl:p-[10rem]">
            <p className="text-base sm:text-xl font-thin text-rose sm:leading-loose">
              Plus tard, j’aimerais ouvrir ma propre boutique, car ce qui me
              plaît le plus, c’est de faire plaisir aux gens avec mes
              pâtisseries. Mais avant ça, je compte acquérir le plus de
              connaissances possible en entreprise !
            </p>
          </div>

          <div className="relative flex justify-center items-center">
            <img
              src="/images/sarah_couleur.jpeg"
              alt="Pâtissière"
              className="w-full h-[calc(50vw)] lg:h-[calc(25vw)] object-cover object-top"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
