import React from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import logo from '../images/logo.jpg';
import backgroundImage from '../images/image-accueil2.jpg';

const NavBarTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollToAbout = () => {
    navigate('/'); // Redirection vers la page d'accueil
    // Délai de 2 secondes avant de défiler
    setTimeout(() => {
      const element = document.getElementById('about');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200); // Délai en millisecondes (2000 ms = 2 s)
  };

  // Créer le lien de contact avec le fragment
  const getContactLink = () => {
    return `${location.pathname}#contactmarron`;
  };

  return (
    <nav
      className="w-full z-50 p-4 bg-cover bg-top"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Conteneur pour la version grand écran */}
      <div className="hidden lg:flex items-center justify-between w-full">
        <div className="flex items-center">
          <img
            src={logo}
            alt="Sarah et la pâtisserie"
            className="h-[10rem] rounded-full object-cover cursor-pointer"
            onClick={() => {
              navigate('/Home');
            }}
          />
        </div>
        <ul className="flex space-x-[5rem] mr-[4rem]">
          <li>
            <NavLink
              to="/"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Accueil
            </NavLink>
          </li>
          <li>
            <button
              onClick={handleScrollToAbout}
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Qui suis-je ?
            </button>
          </li>
          <li>
            <NavLink
              to="/Creations"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Mes réalisations
            </NavLink>
          </li>
          <li>
            <NavLink
              to={getContactLink()}
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Conteneur pour la version tablette */}
      <div className="flex flex-col items-center lg:hidden">
        <div className="mb-4">
          <img
            src={logo}
            alt="Sarah et la pâtisserie"
            className="h-[8rem] w-[8rem] rounded-full object-cover cursor-pointer"
            onClick={() => {
              navigate('/Home');
              window.scrollTo(0, 0);
            }}
          />
        </div>
        <ul className="flex flex-col items-center space-y-4">
          <li>
            <NavLink
              to="/"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Accueil
            </NavLink>
          </li>
          <li>
            <button
              onClick={handleScrollToAbout}
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Qui suis-je ?
            </button>
          </li>
          <li>
            <NavLink
              to="/Creations"
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Mes réalisations
            </NavLink>
          </li>
          <li>
            <NavLink
              to={getContactLink()}
              className="text-marronclair hover:text-rose text-xl font-semibold transition-colors duration-300"
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBarTwo;
