import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import NavBarTwo from '../components/NavBarTwo';
import FooterTwo from '../components/FooterTwo';

const Connexion = () => {
  const [pseudo, setPseudo] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false); // Suivre l'état de connexion
  const [affichagePopupCookie, setAffichagePopupCookie] = useState(true);
  const [wrongIdentifier, setWrongIdentifier] = useState(false);
  const [cookiesAcceptes, setCookiesAcceptes] = useState(false);
  const navigate = useNavigate(); // Initialisez useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_STRAPI_BASE_URL + '/api/auth/local',
        // 'http://192.168.1.22:1337/api/auth/local',
        {
          identifier: pseudo,
          password: password,
        },
        { withCredentials: true }
      );

      // Stockage du token JWT et du pseudo dans localStorage
      Cookies.set('token', response.data.jwt, { expires: 1 });
      //   Cookies.set('pseudo', pseudo);

      setLoginSuccess(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error(
          'Erreur lors de la connexion : Identifiant ou mot de passe incorrect.'
        );
        setWrongIdentifier(true);
      } else {
        console.error('Erreur lors de la connexion : ', error);
      }
    }
  };

  useEffect(() => {
    // Vérifiez si un token existe dans localStorage pour maintenir la session connectée
    const token = Cookies.get('token');
    if (token) {
      setLoginSuccess(true);
    }
  }, []);

  useEffect(() => {
    // Vérification si il y a une connexion, redirection vers la page d'accueil
    if (loginSuccess) {
      setTimeout(() => {
        navigate('/BackOffice');
      }, 1000); // Ajout d'un délai de 1 seconde
    }
  }, [loginSuccess, navigate]);

  const acceptCookies = (e) => {
    setAffichagePopupCookie(false);
    setCookiesAcceptes(true);
  };

  const refuseCookies = (e) => {
    setAffichagePopupCookie(false);
  };

  const affichePopup = (e) => {
    setAffichagePopupCookie(true);
  };

  return (
    <div className="bg-marronclair">
      <NavBarTwo />
      {loginSuccess ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <p className="text-center text-2xl text-marronfonce">
            Connexion en cours... <br />
            Vous allez être redirigé vers la page d'accueil.
          </p>
        </div>
      ) : (
        <div className="mx-4 md:mx-40 my-20 md:my-40 flex flex-col items-center">
          {affichagePopupCookie && (
            <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-marronclair p-4 border border-marronfonce rounded shadow-2xl z-50 border border-solid">
              <div className="text-center ">
                <h2 className="text-marronfonce">Accepter les cookies</h2>
                <p className="text-marronfonce">
                  Nous utilisons des cookies pour vous offrir la meilleure
                  expérience sur notre site web.
                </p>
                <button
                  className="mx-2 mt-2 bg-marronfonce text-white px-4 py-2 rounded cursor-pointer hover:bg-opacity-80"
                  onClick={acceptCookies}
                >
                  Accepter
                </button>
                <button
                  className="mx-2 mt-2 bg-red-300  px-4 py-2 rounded cursor-pointer hover:bg-opacity-80"
                  onClick={refuseCookies}
                >
                  Refuser
                </button>
              </div>
            </div>
          )}

          <h2 className="text-3xl text-marronfonce text-center mb-6">
            Connexion
          </h2>
          <form
            className="flex flex-col bg-marronfonce shadow-2xl rounded px-8 pb-10 pt-10 mb-4 w-full max-w-md"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label
                className="block text-marronclair text-sm font-bold mb-2"
                htmlFor="pseudo"
              >
                Pseudo:
              </label>
              <input
                className="shadow appearance-none border border-marronclair rounded w-full py-2 px-3 text-marronfonce leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={pseudo}
                onChange={(e) => setPseudo(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-marronclair text-sm font-bold mb-2"
                htmlFor="password"
              >
                Mot de passe:
              </label>
              <input
                className="shadow appearance-none border border-marronclair rounded w-full py-2 px-3 text-marronfonce leading-tight focus:outline-none focus:shadow-outline"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {cookiesAcceptes ? (
              <button
                className="bg-marronclair hover:bg-rose text-marronfonce font-bold py-2 rounded focus:outline-none duration-300 focus:shadow-outline mt-5"
                type="submit"
              >
                Se connecter
              </button>
            ) : (
              <>
                <button
                  className="bg-gray-500  text-marronclair font-bold py-2 rounded focus:outline-none focus:shadow-outline mt-5"
                  type="submit"
                  disabled
                >
                  Se connecter
                </button>
                <p className="text-center text-sm mt-2 text-marronclair">
                  Pour vous connecter, veuillez accepter les cookies
                </p>
                <input
                  type="button"
                  className="bg-marronclair hover:bg-rose text-marronfonce text-sm font-bold rounded cursor-pointer duration-300 mt-2"
                  value="Recharger les cookies"
                  onClick={affichePopup}
                />
              </>
            )}

            {wrongIdentifier && (
              <p className="text-center text-rose font-bold mt-4">
                Identifiant ou mot de passe incorrect.
              </p>
            )}
          </form>
        </div>
      )}
      <FooterTwo />
    </div>
  );
};

export default Connexion;
