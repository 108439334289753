import React from 'react';
import HomeBlock from '../components/HomeBlock';
import LastestCreations from '../components/LastestCreations';
import About from '../components/About';
import Footer from '../components/Footer';
import Navbar from '../components/NavBar';
import ScrollToTopButton from '../components/ScrollToTopButton';
import { Helmet } from 'react-helmet';
const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Page d'accueil - Sarah et la pâtisserie</title>
        <meta
          name="description"
          content="Bienvenue sur le site de Sarah et la pâtisserie. Découvrez mes réalisations et contactez-moi pour plus d'informations."
        />
        <meta
          name="keywords"
          content="pâtisserie, gâteaux, créations, Sarah et la pâtisserie, accueil"
        />
      </Helmet>
      <Navbar />

      <ScrollToTopButton />
      <HomeBlock />
      <LastestCreations />
      <About />
      <Footer />
    </div>
  );
};

export default Home;
