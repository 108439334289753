// src/pages/RealisationDetails.jsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import NavBarTwo from '../components/NavBarTwo';
import FooterTwo from '../components/FooterTwo';
import ScrollToTopButton from '../components/ScrollToTopButton';
import { MdArrowBack } from 'react-icons/md';
import { Helmet } from 'react-helmet';

const RealisationDetails = () => {
  const { idRealisation } = useParams(); // Récupérer l'ID depuis l'URL
  const [realisation, setRealisation] = useState(null); // État pour stocker les détails de la réalisation
  const [loading, setLoading] = useState(true); // État pour gérer le chargement

  // Utiliser useEffect pour récupérer les détails de la réalisation lors du montage du composant ou quand l'ID change
  useEffect(() => {
    const fetchRealisation = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_STRAPI_BASE_URL +
            `/api/realisations/${idRealisation}?populate=*`
        );
        setRealisation(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(
          'Erreur lors de la récupération de la réalisation :',
          error.message
        );
        setLoading(false);
      }
    };

    fetchRealisation();
  }, [idRealisation]);

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (!realisation) {
    return <p>Réalisation non trouvée</p>;
  }
  console.log(realisation);
  const { nom, description, image } = realisation.attributes || {};
  return (
    <div className="bg-marronclair relative">
      <Helmet>
        <title>
          {nom
            ? `${nom} - Sarah et la pâtisserie`
            : 'Réalisation - Sarah et la pâtisserie'}
        </title>
        <meta
          name="description"
          content={
            description
              ? description.substring(0, 150) + '...'
              : 'Découvrez les détails de cette réalisation de Sarah et la pâtisserie.'
          }
        />
      </Helmet>
      <NavBarTwo />
      <ScrollToTopButton />
      <div className="container mx-auto px-4 py-8 relative">
        <div className="text-center mb-8">
          <h1 className="text-4xl md:text-5xl font-corinthia text-marronfonce">
            {nom || 'Nom de la réalisation'}
          </h1>
        </div>
        <div className="grid grid-cols-1 gap-8">
          {/* Image de la réalisation */}
          <div className="flex justify-center items-center">
            {image && image.data && image.data.length === 1 && (
              <img
                src={
                  process.env.REACT_APP_STRAPI_BASE_URL +
                  `${
                    image.data[0].attributes.formats.large?.url ||
                    image.data[0].attributes.url
                  }`
                }
                alt={nom}
                className="w-full max-w-md h-auto rounded-lg shadow-lg"
              />
            )}
          </div>
          {/* Description de la réalisation */}
          <div className="flex flex-col justify-center lg:mt-0 mt-4">
            <p className="text-base text-center lg:text-xl lg:px-[24rem] text-marronfonce leading-relaxed italic">
              {description || 'Aucune description disponible.'}
            </p>
          </div>
          {/* Lien de retour aux réalisations */}
          <Link
            to="/Creations"
            className="flex items-center text-marronfonce hover:opacity-90 mt-8"
          >
            <MdArrowBack size={24} />
            <span className="ml-2 text-sm">Retour aux réalisations</span>
          </Link>
        </div>
      </div>

      <FooterTwo />
    </div>
  );
};

export default RealisationDetails;
