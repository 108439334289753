import BackOffice from './pages/BackOffice';
import Connexion from './pages/Connexion';
import Creations from './pages/Creations';
import Home from './pages/Home';
import RealisationDetails from './pages/RealisationDetails';
import './styles/index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import LegalMentions from './pages/LegalMentions';

const PrivateRoute = ({ element }) => {
  const token = Cookies.get('token');
  return token ? (
    element
  ) : (
    <Navigate
      to="/Connexion"
      replace
      state={{ from: window.location.pathname }}
    />
  );
};
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/BackOffice"
            element={<PrivateRoute element={<BackOffice />} />}
          />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Creations" element={<Creations />} />
          <Route path="/BackOffice" element={<BackOffice />} />
          <Route path="/LegalMentions" element={<LegalMentions />} />
          <Route
            path="/RealisationDetails/:idRealisation"
            element={<RealisationDetails />}
          />
          <Route path="/Connexion" element={<Connexion />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
