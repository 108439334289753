import React, { useEffect, useState } from 'react';
import CardCreation from '../components/CardCreation'; // Composant de la carte
import axios from 'axios';
import NavBarTwo from '../components/NavBarTwo';
import FooterTwo from '../components/FooterTwo';
import ScrollToTopButton from '../components/ScrollToTopButton';
import { Helmet } from 'react-helmet';

const Creations = () => {
  const [realisations, setRealisations] = useState([]); // État pour stocker les réalisations
  const [loading, setLoading] = useState(true); // État pour gérer le chargement
  const [sortOrder, setSortOrder] = useState('desc'); // État pour l'ordre de tri ('asc' ou 'desc')

  useEffect(() => {
    const fetchRealisations = async () => {
      try {
        // Requête GET pour récupérer les réalisations de Strapi
        const response = await axios.get(
          process.env.REACT_APP_STRAPI_BASE_URL + '/api/realisations?populate=*'
        );
        const sortedRealisations = sortRealisations(
          response.data.data,
          sortOrder
        );
        setRealisations(sortedRealisations); // Stocker les réalisations triées dans l'état
        setLoading(false); // Fin du chargement
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des réalisations :',
          error
        );
        setLoading(false);
      }
    };

    fetchRealisations();
  }, [sortOrder]); // Dépendance sur `sortOrder` pour re-trier lors du changement d'ordre

  // Fonction pour trier les réalisations par date
  const sortRealisations = (data, order) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.attributes.publishedAt);
      const dateB = new Date(b.attributes.publishedAt);

      if (order === 'asc') {
        return dateA - dateB; // Tri ascendant (du plus ancien au plus récent)
      } else {
        return dateB - dateA; // Tri descendant (du plus récent au plus ancien)
      }
    });
  };

  if (loading) {
    return <p>Chargement des réalisations...</p>; // Message de chargement
  }

  return (
    <div className="bg-marronclair">
      <Helmet>
        <title>Réalisations - Sarah et la pâtisserie</title>
        <meta
          name="description"
          content="Bienvenue sur le site de Sarah et la pâtisserie. Découvrez mes réalisations et contactez-moi pour plus d'informations."
        />
        <meta
          name="keywords"
          content="pâtisserie, gâteaux, réalisations, créations, Sarah et la pâtisserie"
        />
      </Helmet>
      <NavBarTwo />

      <ScrollToTopButton />
      <div className="flex sm:flex-row items-center justify-center">
        <img
          src="/images/gateau1.png"
          alt="Gâteau"
          className="h-[4rem] sm:h-[6rem] lg:h-[8rem] mb-2 sm:mb-0"
        />
        <h2 className="text-center text-marronfonce text-lg sm:text-xl md:text-2xl font-bold mx-4">
          Mes réalisations
        </h2>
        <img
          src="/images/gateau1.png"
          alt="Gâteau"
          className="h-[4rem] sm:h-[6rem] lg:h-[8rem] mb-2 sm:mb-0 transform scale-x-[-1]"
        />
      </div>
      <hr className="border-t-2 border-marronfonce w-11/12 sm:w-3/4 lg:w-1/2 mx-auto mb-10" />

      {/* Bandeau de filtre */}
      <div className="text-marronfonce px-4 py-2 sm:px-6 sm:py-3 flex justify-center items-center mb-6">
        <span className="font-semibold mr-2 sm:mr-4">Filtrer :</span>
        <select
          onChange={(e) => setSortOrder(e.target.value)}
          value={sortOrder}
          className="bg-white text-marronfonce font-semibold py-1 px-2 sm:py-1 sm:px-4 rounded"
        >
          <option value="desc">Du plus récent au plus ancien</option>
          <option value="asc">Du plus ancien au plus récent</option>
        </select>
      </div>

      <div className="grid grid-cols-1 gap-6 p-4 sm:p-6 lg:px-20 sm:grid-cols-2 lg:grid-cols-3">
        {realisations.map((realisation) => {
          // Sélectionner le format d'image préféré
          const imageData = realisation.attributes.image.data[0].attributes;
          const imageUrl =
            imageData.formats.large?.url ||
            imageData.formats.medium?.url ||
            imageData.formats.small?.url ||
            imageData.url;
          const fullImageUrl = `${process.env.REACT_APP_STRAPI_BASE_URL}${imageUrl}`;

          return (
            <CardCreation
              key={realisation.id}
              index={realisation.id}
              idGateau={realisation.id}
              nomGateau={realisation.attributes.nom}
              imageGateau={fullImageUrl}
            />
          );
        })}
      </div>
      <FooterTwo />
    </div>
  );
};

export default Creations;
