import React from 'react';
import NavBarTwo from '../components/NavBarTwo';
import FooterTwo from '../components/FooterTwo';
import { Helmet } from 'react-helmet';

const LegalMentions = () => {
  return (
    <div className="bg-marronclair">
      <Helmet>
        <title>Mentions légales - Sarah et la pâtisserie</title>
        <meta
          name="description"
          content="Retrouvez les informations légales du site Sarah et la pâtisserie, ainsi que les détails sur la propriété intellectuelle et la protection des données personnelles."
        />
        <meta
          name="keywords"
          content="mentions légales, Sarah et la pâtisserie, propriété intellectuelle, contact, données personnelles"
        />
      </Helmet>

      <NavBarTwo />
      <div className="container mx-auto px-4 lg:px-[10rem] py-8">
        <h1 className="text-4xl md:text-5xl font-corinthia text-marronfonce text-center mb-8">
          Mentions Légales
        </h1>
        <div className="prose lg:prose-xl mx-auto text-marronfonce">
          <h2 className="underline font-bold">1. Informations Générales</h2>
          <p>
            Conformément aux dispositions des articles 6-III et 19 de la Loi n°
            2004-575 du 21 juin 2004 pour la confiance dans l'économie
            numérique, il est précisé aux utilisateurs du site www.example.com
            l'identité des différents intervenants dans le cadre de sa
            réalisation et de son suivi.
          </p>
          <h3 className="underline font-bold">Propriétaire</h3>
          <p>
            Sarah Vandenbussche
            <br />
            Adresse : 319 rue de Vimy 62580 Thélus
            <br />
            Email : sasavdb62@gmail.com
          </p>

          <h3 className="underline font-bold">Responsable de la publication</h3>
          <p>Sarah Vandenbussche</p>

          <h3 className="underline font-bold">Hébergement :</h3>
          <p>
            L'hébergement du site est assuré par Hostinger
            <br />
            Adresse : Jonavos g. 60C, Kaunas 44192, Lituanie
          </p>

          <h2 className="underline font-bold">2. Propriété Intellectuelle</h2>
          <p>
            L'ensemble des contenus présents sur ce site (textes, images,
            vidéos, logos, etc.) sont protégés par les lois en vigueur sur la
            propriété intellectuelle. Toute reproduction ou utilisation des
            contenus est interdite sans autorisation préalable.
          </p>

          <h2 className="underline font-bold">3. Données Personnelles</h2>
          <p>
            Ce site ne collecte pas de données personnelles à des fins
            commerciales. Les informations recueillies via le formulaire de
            contact (nom, email, message) sont uniquement utilisées pour
            répondre aux demandes des utilisateurs. Ces informations ne sont pas
            stockées de manière prolongée et ne sont jamais partagées avec des
            tiers.
          </p>

          <h2 className="underline font-bold">4. Responsabilité</h2>
          <p>
            Sarah Vandenbussche ne saurait être tenue pour responsable des
            erreurs ou omissions sur le site, de l'indisponibilité temporaire du
            site, ou des dommages directs ou indirects pouvant résulter de
            l'utilisation de ce site.
          </p>

          <h2 className="underline font-bold">5. Liens Hypertextes</h2>
          <p>
            Le site www.Sarahetlapatisserie.fr peut contenir des liens vers
            d'autres sites. "Sarah et la pâtisserie" n'exerce aucun contrôle sur
            ces sites et ne peut être tenue responsable de leur contenu.
          </p>

          <h2 className="underline font-bold">6. Modifications</h2>
          <p>
            "Sarah et la pâtisserie" se réserve le droit de modifier les
            présentes mentions légales à tout moment. Les utilisateurs sont
            invités à consulter régulièrement cette page pour prendre
            connaissance des éventuelles modifications.
          </p>

          <h2 className="underline font-bold">7. Droit Applicable</h2>
          <p>
            Les présentes mentions légales sont régies par le droit français.
            Tout litige relatif à l'utilisation du site sera soumis aux
            tribunaux compétents.
          </p>
          <h2 className="underline font-bold">8. Cookies</h2>
          <p>
            Ce site n'utilise aucun cookie à des fins de suivi, de
            personnalisation ou de publicité.
          </p>
        </div>
      </div>
      <FooterTwo />
    </div>
  );
};

export default LegalMentions;
