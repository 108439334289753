import React from 'react';
import { useNavigate } from 'react-router-dom';

const CardCreation = ({ index, idGateau, nomGateau, imageGateau }) => {
  const navigate = useNavigate();

  return (
    <div
      key={index}
      className="group relative w-[20rem] h-[26rem] p-4 z-1 shadow-xl border-solid  border-marronfonce rounded-lg mx-auto"
    >
      <div className="w-full h-[20rem] overflow-hidden lg:aspect-none lg:h-80">
        <div
          className="w-full h-full bg-cover bg-center z-0 lg:hover:opacity-75 cursor-pointer duration-150"
          style={{
            backgroundImage: `url(${imageGateau})`,
          }}
          onClick={() => {
            navigate(`/RealisationDetails/${idGateau}`);
            window.scrollTo(0, 0);
          }}
        >
          <div className="absolute inset-0 flex items-center hidden lg:flex justify-center opacity-0 hover:opacity-100 duration-300">
            <h2 className="hidden lg:block text-center font-bold text-2xl text-black">
              Voir la recette
            </h2>
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div>
          <h3 className="text-xl font-medium text-center">{nomGateau}</h3>
        </div>
      </div>
    </div>
  );
};

export default CardCreation;
